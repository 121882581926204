var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.authData)?_c('div',[_c('van-nav-bar',{staticClass:"h-[7%]",attrs:{"left-text":"Atrás","left-arrow":"","right-text":"Salir"},on:{"click-left":_vm.goBack,"click-right":_vm.confirmLogOut},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"flex flex-col items-center"},[_c('span',{class:{
            'text-green-500': _vm.isOnline,
            'text-orange-500': !_vm.isOnline,
            'text-xs': true,
          }},[_vm._v(_vm._s(_vm.isOnline ? "En línea" : "Desconectado"))]),_c('span',{staticClass:"text-[#007EAF] drop-shadow-md font-semibold"},[_vm._v(" LINKTRACE ")])])]},proxy:true}],null,false,1312876170)}),_c('router-view',{staticClass:"overflow-scroll bg-slate-100",class:_vm.isKeyboardOpen ? 'h-[93%]' : 'h-[86%]',attrs:{"authData":_vm.authData,"isKeyboardOpen":_vm.isKeyboardOpen}}),(!_vm.isKeyboardOpen)?_c('van-tabbar',{staticClass:"h-[7%]",attrs:{"route":""}},_vm._l((_vm.authData.user.RoleName === 'SUPERVISOR'
        ? _vm.Tier1Routes
        : _vm.Tier2Routes),function(route){return _c('van-tabbar-item',{key:route.name,attrs:{"replace":"","to":route.path,"icon":route.icon}},[_vm._v(_vm._s(route.name))])}),1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }