<template>
  <div>
    <component :is="layout" class="h-screen"> </component>
    <van-dialog
      v-model="navigationDenied"
      title="Modo horizontal"
      message="Por favor, gire su dispositivo a modo vertical"
      :show-confirm-button="false"
    />
  </div>
</template>
<script>
//import auth from "@/services/auth";
export default {
  data() {
    return {
      navigationDenied: false,
    };
  },
  computed: {
    layout() {
      return this.$route.meta.Layout || "MainLayout";
    },
  },
  created() {
    window.addEventListener("error", function (event) {
      console.log("Got an uncaught error: ", event.error);
    });
    window.addEventListener("orientationchange", this.handleOrientationChange);
    if (window.screen.orientation.type === "landscape-primary")
      this.navigationDenied = true;
  },
  methods: {
    handleOrientationChange() {
      const orientation = window.screen.orientation.type;
      if (orientation === "portrait-primary") {
        this.navigationDenied = false;
      } else if (orientation === "landscape-primary") {
        this.navigationDenied = true;
      }
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
