import { Dialog, Toast } from "vant";
import auth from "./auth";
export function interceptor(e) {
  Toast.clear();
  let message = "";
  let title = "";
  let redirect = false;
  let logout = false;
  switch (e.response.status) {
    case 0:
      title = "Error de conexión";
      message = "Actualmente se encuentra offline.";
      redirect = false;
      logout = false;
      break;
    case 500:
      title = "Error de servidor";
      message =
        "Intente de nuevo, si el problema persiste contacte al administrador";
      redirect = true;
      logout = false;
      break;
    case 400:
      title = "Error en la información";
      message =
        "Verifique la información ingresada, si el problema persiste contacte al administrador";
      redirect = false;
      logout = false;
      break;
    case 422:
      title = "Error en la información";
      message =
        "Verifique la información ingresada, si el problema persiste contacte al administrador";
      redirect = false;
      logout = false;
      break;
    case 401:
      title = "Error de autenticación";
      message = "Su sesión ha expirado, por favor inicie sesión nuevamente";
      redirect = false;
      logout = true;
  }
  Dialog.alert({
    title,
    message,
  }).then(() => {
    if (redirect) {
      window.location.href = "/";
    }
    if (logout) {
      auth.logout().then(() => {
        window.location.href = "/";
      });
    }
  });
}
