import axios from "axios";
import localforage from "localforage";
import FormData from "form-data";
import Cookies from "vue-cookies";
import { interceptor } from "./requestInterceptor";
const AUTH_ENDPOINT_PATH = "https://api.dev.linktrace.app/api/auth/";

function getUserLogged() {
  let isUserLogged = Cookies.isKey("authData");
  if (isUserLogged) {
    let authData = JSON.parse(JSON.stringify(Cookies.get("authData")));
    return authData;
  } else {
    return false;
  }
}
function simpleHash(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash;
  }
  return hash;
}

async function login(IdUsuario, password) {
  let authData = getUserLogged();
  let isAutoLogin =
    authData &&
    authData.isLogged === false &&
    !navigator.onLine &&
    authData.user.IdUsuario == IdUsuario;
  if (isAutoLogin) {
    console.log("auto login attempt");
    const hashedPassword = simpleHash(password);
    if (
      authData.user.IdUsuario == IdUsuario &&
      authData.user.passwordHash === hashedPassword
    ) {
      console.log("auto login verified");
      const loggedAtDate = new Date(authData.loggedAt);
      const expiresAtDate = new Date(
        loggedAtDate.getTime() + authData.expires_in * 1000
      );
      if (expiresAtDate > new Date()) {
        console.log("auto login success");
        authData.isLogged = true;
        Cookies.set("authData", JSON.stringify(authData));
        return { autoLogin: true, data: authData };
      }
    }
  } else {
    Cookies.remove("authData");
    await localforage.setItem("DATA_PRELOADED", false);
    var data = new FormData();
    data.append("IdUsuario", IdUsuario);
    data.append("password", password);
    var config = {
      method: "post",
      url: AUTH_ENDPOINT_PATH + "login",
      headers: data.getHeaders
        ? data.getHeaders()
        : { "Content-Type": "multipart/form-data" },
      data: data,
    };
    return await axios(config).catch((e) => {
      interceptor(e);
    });
  }
}
async function registerUser(userData) {
  var data = new FormData();
  data.append("IdUsuario", userData.IdUsuario);
  data.append("password", userData.password);
  data.append("RoleName1", userData.RoleName1);
  data.append("RoleName2", userData.RoleName2);
  data.append("NomPersonal", userData.NomPersonal);
  data.append("ApePersonal", userData.ApePersonal);
  data.append("IndHabilitado", "1");

  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "register",
    headers: data.getHeaders
      ? data.getHeaders()
      : { "Content-Type": "multipart/form-data" },
    data: data,
  };

  return await axios(config).catch((e) => {
    interceptor(e);
  });
}
async function logout(force) {
  let authData = this.getUserLogged();
  if (authData) {
    authData.isLogged = false;
    Cookies.set("authData", authData);
  }

  if (force) {
    if (navigator.onLine) {
      var config = {
        method: "post",
        url: AUTH_ENDPOINT_PATH + "logout",
        headers: {
          Authorization: `Bearer ${getUserLogged().access_token}`,
        },
      };
      await axios(config)
        .then(function () {
          return true;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    Cookies.remove("authData");
    localforage.clear();
  }
}

async function getUser(id) {
  var data = new FormData();
  data.append("IdUsuario", id);
  var config = {
    method: "post",
    url: AUTH_ENDPOINT_PATH + "user",
    headers: {
      Authorization: `Bearer ${getUserLogged().access_token}`,
    },
    data: data,
  };
  return await axios(config).catch((e) => {
    interceptor(e);
  });
}

export default {
  getUserLogged,
  login,
  registerUser,
  logout,
  getUser,
};
