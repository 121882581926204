import Vue from "vue";
import App from "./App.vue";
// Pinia libraries
import { createPinia, PiniaVuePlugin } from "pinia";
import VueCompositionAPI from "@vue/composition-api";

// Storage manager
import { VuePersistentStorageManager } from "vue-persistent-storage-manager";
Vue.use(VuePersistentStorageManager, { watchStorage: true });

// Pinia use
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
Vue.use(VueCompositionAPI);

// Router
import router from "./router";
import VueCookies from "vue-cookies";

// Vant UI and Language configuration
import Vant from "vant";
import "vant/lib/index.css";
import { Locale } from "vant";
import esES from "vant/es/locale/lang/es-ES";
Locale.use("es-ES", esES);

// Tailwind CSS
import "./assets/tailwind.css";

// Indexed DB manager
import Vlf from "vlf";
import localforage from "localforage";
localforage.config({
  driver: localforage.INDEXEDDB,
  name: "LinkTrace",
  version: 1.0,
  storeName: "link_trace_db",
  description: "Temp data storage",
});
Vue.use(Vlf, localforage);

// App layouts
import MainLayout from "@/layouts/MainLayout.vue";
import PublicLayout from "@/layouts/PublicLayout.vue";
Vue.component("PublicLayout", PublicLayout);
Vue.component("MainLayout", MainLayout);

Vue.config.productionTip = false;
Vue.use(VueCookies, { expire: "7d" });
Vue.use(Vant);

// service worker register
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/sw.js")
      .then((registration) => {
        console.log("Service Worker registrado con éxito:", registration);
      })
      .catch((registrationError) => {
        console.log("Registro de Service Worker fallido:", registrationError);
      });
  });
}
new Vue({
  pinia,
  router,
  render: (h) => h(App),
}).$mount("#app");
